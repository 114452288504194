// ======= Base =======

// == Add class "js" to <html> if js is enabled ==
document.documentElement.classList.add("js");
document.documentElement.classList.remove("no-js");

// Navigation and modal
jQuery(document).ready(function(){
	// Mobile nav button
	jQuery("#button-mobile-nav").click(function() {
		jQuery("body").toggleClass( "nav-open");
	});
});

// Toggle class on the primary navigation parent that has children
jQuery(".menu-collapse").click( function (){
    jQuery(this).parent().toggleClass("collapsed");
});
