// ======= Flickity slideshow =======

jQuery('.main-product-carousel').flickity({
	"pageDots": false,
	"adaptiveHeight": true,
	"imagesLoaded": true
});
jQuery('.nav-product-carousel').flickity({
	"asNavFor": ".main-product-carousel", 
	"contain": true, 
	"pageDots": false, 
	"prevNextButtons": false,
	"imagesLoaded": true
});
jQuery('.standard-carousel-sg').flickity({
	"pageDots": true,
	"prevNextButtons": false,
	"adaptiveHeight": true,
	"imagesLoaded": true
});
jQuery('.frontpage-carousel-sg').flickity({
	"pageDots": true,
	"prevNextButtons": true,
	"imagesLoaded": true
});